import {get, post} from "@/utils/axios"

// 获取消息列表
const getMessageList = p => get('api-seller/message/getMessageList', p)

// 删除消息
const syncMessageDelList = p => post('api-seller/message/syncMessageDelList', p)

// 标记已读消息
const syncMessageReadList = p => post('api-seller/message/syncMessageReadList', p)

// 全部已读
const syncMessageReadAll = p => post('api-seller/message/syncMessageReadAll', p);

// 全部删除
const syncMessageDeleteAll = p => post('api-seller/message/syncMessageDeleteAll', p);

// 单个消息已读
const syncMessageRead = p => post('api-seller/message/syncMessageDeleteAll', p);

export default{
    getMessageList,
    syncMessageDelList,
    syncMessageReadList,
    syncMessageReadAll,
    syncMessageDeleteAll,
    syncMessageRead,
}