<template>
<div class="provide">
    <div class="provide-title">
        消息中心
    </div>
    <div class="main-frame">
        <div class="left-menu">
            <div class="message">
                <div class="message-button-ful">
                    <checkbox class="checkbox checkbox-all" @on-change="checkAll()" v-model="check"></checkbox>
                    <i-button type="error" :disabled="disabled" class="message-button" @click="deleteMessage()">删除</i-button>
                    <i-button type="error" :disabled="disabled" class="message-button" @click="read()">标记已读</i-button>
                    <i-select v-model="timeType" style="width:100px" @on-change="getMessageList" class="message-button">
                        <i-option v-for="(item,index) in timeTypeList" :value="item.value" :key="index">{{ item.label }}</i-option>
                    </i-select>
                    <i-button type="error" class="message-button" @click="readAll()">全部标记</i-button>
                    <i-button type="error" class="message-button" @click="deleteAll()">全部删除</i-button>
                </div>
            </div>
            <!-- 信息主体 -->
            <div class="message-title-main" v-if="data.length > 0">
                <checkbox-group v-model="messageCheck" style="height: 100%" :indeterminate="indeterminate" @on-change="groupChange">
                    <div class="message-button-ful message" v-for="(item,index) in data" :key="index">
                        <checkbox class="checkbox" :label="item.id"></checkbox>
                        <i class="yuan" v-if="item.isRead == 0"></i>
                        <i class="yuan-none" v-else></i>
                        <div class="message-title-ful message-button" @click="messageDetail(index)">
                            <div class="title">{{item.title}}</div>
                            <i>{{item.createTime}}</i>
                        </div>
                    </div>
                </checkbox-group>
            </div>
            <div class="table-page">
                <Page :total="pageTotal" :current="pageCurrent" :page-size="pageSize" @on-change="changePage"
                      @on-page-size-change="onPageSizeChange" show-elevator size="small"
                      show-total show-sizer></Page>
            </div>

        </div>
        <div class="message-main" v-if="data.length > 0">
            <div class="message-main-top">
                <div class="message-main-title">{{detailData.title}}</div>
                <i style="font-size:14px">发布于{{detailData.createTime}}</i>
            </div>
            <div class="message-content">
                <span>{{detailData.content}}</span>
            </div>
        </div>
        <div v-else>
            <img src="@/img/messageCenter/d20422c.png" style="margin: 200px 0 0 300px;">
        </div>
    </div>
</div>
</template>
<script>
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import Vue from 'vue'
import message from '@/api/buyer/message/messageCenter'

Vue.use(iView)
export default ({
    name: 'messageCenter',
    data() {
        return {
            pageTotal: 20,
            pageCurrent: 1,
            pageSize: 20,
            disabled: true,
            check: false, // 全选
            indeterminate: false,
            messageCheck: [], //
            data: [
                {
                    id: 1,
                    title: "伊登通知",
                    createTime: "2021-02-12 14:20:01",
                    isRead: 0
                },{
                    id: 2,
                    title: "伊登通知",
                    createTime: "2021-02-12 14:20:01",
                    isRead: 0
                },{
                    id: 3,
                    title: "伊登通知",
                    createTime: "2021-02-12 14:20:01",
                    isRead: 0,
                }
            ],
            detailData: {
                title: "伊登通知",
                createTime: "2021-02-12 14:20:01",
                content: "您有最新的优惠券满399减100可使用",
            },
            timeType: 3,
            timeTypeList: [
                {
                    value: 3,
                    label: '近3个月'
                },{
                    value: 2,
                    label: '近半年'
                },{
                    value: 4,
                    label: '近9个月'
                },{
                    value: 5,
                    label: '近一年'
                }
            ],
        }
    },
    mounted() {
      document.body.style.overflow = 'hidden'
        this.getMessageList();
    },
    methods: {
        // 获取消息列表
        getMessageList() {
            console.log(this.detailData)
            console.log(this.messageCheck);
            let obj = this;
            obj.handleSpinCustom();
            message.getMessageList({
                'pageNo': obj.pageCurrent,
                'pageSize': obj.pageSize,
                'userType': 1,
                'timeType': obj.timeType
            }).then((result) => {
                    obj.$Spin.hide();
                    obj.data = [];
                    obj.pageTotal = 0;
                    if(result.code == 200) {
                        obj.data = result.data.sendmsgLogList;
                        obj.pageTotal = result.data.total;
                        obj.detailData = obj.data[0];
                    }
            })
        },
        // 显示loading
        handleSpinCustom() {
                this.$Spin.show({
                    render: (h) => {
                        return h('div', [
                            h('Icon', {
                                'class': 'demo-spin-icon-load',
                                props: {
                                    type: 'ios-loading',
                                    size: 18
                                }
                            }),
                            h('div', 'Loading')
                        ])
                    }
                });
        },
        // 展示消息的详细内容
        messageDetail(index) {
            console.log(index);
            let obj = this;
            obj.detailData = {};
            obj.detailData.push(obj.data[index]);
            // 去除未读标记
            message.syncMessageRead(obj.detailData).then((result) => {
                obj.$Spin.hide();
                if(result.code == 200) {
                    obj.data[index].isRead = 1;
                }
            })
        },
        // 勾选全部
        checkAll(){
            let obj = this;
            if(obj.check) {
                // 全选
                obj.disabled = false;
                for(let i = 0 ; i < obj.data.length; i++) {
                    let data = obj.data[i];
                    obj.messageCheck.push(data.id);
                }
            } else {
                // 取消全选
                obj.disabled = true;
                obj.messageCheck = [];
            }
        },
        // 删除
        deleteMessage() {
            let obj = this;
            obj.handleSpinCustom();
            message.syncMessageDelList(obj.messageCheck).then((result) => {
                if(result.code == 200) {
                    obj.getMessageList();
                    obj.check = false;
                    obj.messageCheck = [];
                    obj.disabled = true;
                }
            })
        },
        // 标记已读
        read() {
            let obj = this;
            obj.handleSpinCustom();
            message.syncMessageReadList(obj.messageCheck).then((result) => {
                obj.$Spin.hide();
                if(result.code == 200) {
                    obj.$Message.success('标记成功');
                    for(let i = 0 ;i < obj.data.length; i++) {
                        for(let j = 0 ; j < obj.messageCheck.length; j++) {
                            if(obj.data[i].id == obj.messageCheck[j]) {
                                    obj.data[i].isRead = 1;
                            }
                        }
                    }
                    obj.check = false;
                    obj.messageCheck = [];
                    obj.disabled = true;
                    }
            })
        },
        // 全部已读
        readAll() {
            let obj = this;
            obj.handleSpinCustom();
            message.syncMessageReadAll({'userType': 1}).then((result) => {
                obj.$Spin.hide();
                if(result.code == 200) {
                    for(let i = 0 ; i < obj.data.length; i++) {
                        obj.data[i].isRead = 1;
                    }
                }
            })
        },
        // 全部删除
        deleteAll() {
            let obj = this;
            obj.handleSpinCustom();
            message.syncMessageDeleteAll({'userType': 1}).then((result) => {
                obj.$Spin.hide();
                if(result.code == 200) {
                    obj.data = [];
                }
            })
        },
        // 多选
        groupChange(data){
            let obj = this;
            if(data.length == obj.data.length) {
                obj.check = true;
                obj.indeterminate = false;
                obj.disabled = false;
            } else if (data.length > 0) {
                obj.indeterminate = true;
                obj.check = false;
                obj.disabled = false;
            } else {
                obj.indeterminate = false;
                obj.check = false;
                obj.disabled = true;
            }
        },
        changePage(index) {
            let obj = this;
            obj.pageCurrent = index;
            obj.mockData();
        },
        onPageSizeChange(index) {
            let obj = this;
            obj.pageSize = index;
            obj.mockData();
        },
    },
    created() {
      console.log('===========================================')
      document.body.style.overflow = 'hidden !important'
    }
})
</script>


<style scoped>
@import './messageCenter.css';
.provide {
    margin: 20px 35px;
    min-width: 1130px;
}
.provide-title {
    color: #F56E65;
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 40px;
}

</style>
